@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.textInput {
  @include displayFlex(null, null, column);

  &__item {
    box-sizing: border-box;
    padding: 0 10px;
    height: 42px;
    width: 200px;
    border: 1px solid $custom-input-border;
    border-radius: 5px;
    outline: none;
    text-align: center;
    font: 18px $quote-terms-secondary-font;

    &:focus {
      @include inputFocused;
    }
  }
}
