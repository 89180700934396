@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.first {
  @include displayFlex(center);
  flex-wrap: wrap;

  &__header {
    @include displayFlex(null, center, column);
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }
}

