@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.quoteTermsWrapper {
  @include displayFlex(null, null, column);
  box-sizing: border-box;
  min-height: 560px;
  padding: 30px 20px;
  border: 1px solid $border;
  border-radius: 10px;
  background-color: $main-white;
  transition: min-height 2s ease-out;

  @include maxW_425 {
    margin-top: 55px;
    padding: 20px 10px;
    border-width: 1px 0;
    border-radius: 0;
  }

  &__header {
    position: relative;
    @include displayFlex(space-between, center);
    height: 40px;
    font-weight: 600;

    @include maxW_768 {
      @include displayFlex(null, flex-start, column);
    }
  }

  &__sheetType {
    color: $quote-terms-text;

    @include maxW_375 {
      font-size: 13px;
    }
  }

  &__content {
    @include displayFlex(flex-start);
    position: relative;
    flex: 1;
    padding: 50px 0 120px;

    @include maxW_375 {
      padding: 30px 0 110px;
    }
  }

  &__footer {
    @include displayFlex(space-between, center, column);
    position: relative;
    max-height: 140px;
  }

  &__sliderWrapper {
    @include displayFlex(null, center, column);
    width: 100%;
  }
}
