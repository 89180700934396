@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.loanRequest {
  padding: 20px 15px;
  border: 1px solid $border;
  background-color: $main-white;
  border-radius: 10px;
  font-weight: 600;

  @include maxW_425 {
    padding: 15px 10px;
    margin-bottom: 0;
    border: 0px;
    border-top: 1px dotted $border;
    border-radius: 0;
  }

  &__text {
    display: flex;
    align-items: baseline;
    gap: 10px;

    @include maxW_768 {
      flex-direction: column;
      gap: 0;
    }
    font-family: $main-font;
    font-size: 18px;
    font-weight: 600;

    @include maxW_425 {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__total {
    font-size: 48px;
    font-weight: 500;
    font-family: Poppins;
    font-weight: 500;
    color: $loan-request-title-color;

    @include maxW_675 {
      font-size: 34px;
    }

    @include maxW_425 {
      font-size: 24px;
    }
  }

  @include print {
    display: none;
  }
}
