@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.header {
  box-sizing: border-box;
  @include displayFlex(center, center);
  height: 120px;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid $border;
  background-color: $main-white;

  @include maxW_425 {
    height: 90px;
    padding: 0;
    border-bottom: none;
  }

  &__container {
    @include displayFlex(space-between, center);
    width: 100%;
    max-width: 950px;
    padding: 10px;
  }

  &__left {
    @include displayFlex(0, center);
  }

  &__logo {
    width: 50px;
    height: 50px;

    @include maxW_425 {
      width: 30px;
      height: 30px;
    }
  }

  &__title {
    margin-left: 10px;
    font-size: 42px;
    font-weight: 500;

    @include maxW_425 {
      font-size: 28px;
    }
  }

  &__right {
    @include displayFlex(0, center);

    @include print {
      display: none;
    }
  }
}

.learnMore {
  @include displayFlex();
  font-size: 18px;
  margin-left: 16px;

  @include maxW_768 {
    display: none;
  }
}
