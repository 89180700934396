@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.fifth {
  &__header {
    width: 100%;
    text-align: center;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding-top: 25px;
  }

  &__formSelect{
    margin-top: 13px;
  }

  &__label {
    display: flex;
    color: $main-white;
    font: normal 600 14px/18px $main-font;
    padding: 15px 0 9px;
  }

  &__input {
    display: block;
    outline: none;
    margin-top: 10px;
    padding: 10px 12px;
    height: 42px;
    border-radius: 6px;
    box-sizing: border-box;
    background: none;
    width: 300px;
    font-size: 14px;
     &::placeholder {
       color: $main-white;
     }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }
}


