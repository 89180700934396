@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.inputs {
  @include displayFlex();
  gap: 65px;
  flex-wrap: wrap;

  @include maxW_675 {
    column-gap: 60px;
    row-gap: 25px;
  }
}

.highlightedData {
  color: $quote-terms-highlighted-text;
  font-weight: 600;
}
