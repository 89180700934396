@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.fourth {
  &__header {
    width: 100%;
    text-align: center;
  }

  &__inputsContainer {
    @include displayFlex(center);
    margin: 10px 0;
  }

  &__inputs {
    @include displayFlex(null, null, column);
    gap: 20px;
    padding: 25px 10px;
  }

  &__inputsRow {
    display: flex;
    gap: 20px;
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }
}
