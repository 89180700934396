@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.Buttons {
  display: grid;
  grid-template-columns: repeat(2, 30%);
  justify-content: center;
  column-gap: 20px;
  padding: 30px 0;
  border-radius: 10px;
  border: 1px solid $border;
  background-color: $main-white;

  &_declined {
    display: flex;
  }

  &_hidden {
    @include maxW_425 {
      display: none;
    }
  }

  @include maxW_580 {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 10px;
    margin-top: 50px;
  }

  @include maxW_425 {
    border: 1px solid $border;
    border-radius: 0;
    border-width: 1px 0;
  }
}
