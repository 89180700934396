@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.projectInfoCard {
  padding: 30px 20px 40px;
  border: 1px solid $border;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_768 {
    margin-bottom: 10px;
  }

  @include maxW_425 {
    padding: 0 0 20px 0;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    border-top: 1px dotted $border;
    border-bottom: 1px solid $border;
  }

  &__additionalInfo {
    font-size: 16px;
    font-weight: 500;

    @include maxW_425 {
      padding: 0 10px;
      font-size: 14px;
    }
  }

  @include print {
    padding: 0;
    border: 0;
    background: transparent;
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  @include maxW_425 {
    flex-wrap: nowrap;
    padding: 15px 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 40px;

    @include maxW_425 {
      margin-right: 20px;
    }
  }

  &__icon {
    height: 30px;
    width: 30px;

    @include maxW_425 {
      height: 20px;
      width: 20px;
    }
  }

  &__text {
    margin-left: 10px;
    font-weight: 600;

    @include maxW_425 {
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

.closingDate {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;

  @include maxW_425 {
    margin: 0;
    padding: 15px 10px;
  }

  &__date {
    margin-left: 5px;
    font-weight: 600;
  }

  &__icon {
    height: 30px;
    width: 30px;

    @include maxW_768 {
      display: none;
    }
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-left: 20px;

  @include maxW_425 {
    padding-left: 30px;
  }

  &__item {
    font-size: 18px;
    font-weight: 600;

    @include maxW_425 {
      font-size: 14px;
    }

    &::marker {
      color: $green-text;
    }
  }

  &__text {
    position: relative;
    left: -5px;
  }
}
