@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.inputs {
  @include displayFlex(null, null, column);
  gap: 25px;
  margin-top: 35px;

  @include maxW_768 {
    width: 100%;
  }

  @include maxW_375 {
    @include displayFlex(null, flex-start, column);
  }
}

.section {
  @include displayFlex(flex-end, flex-end);
  gap: 20px;

  @include maxW_768 {
    @include displayFlex(flex-end, flex-start, column);
    flex-wrap: wrap;
    width: 100%;

    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid $quote-terms-section-border;
    }
  }

  &__fields {
    display: flex;
    gap: 20px;

    @include maxW_675 {
      @include displayFlex(null, flex-start, column);
    }
  }
}
