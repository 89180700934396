@import 'assets/scss/variables'

.fallback
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background-color: $main-background-color

  &__content
    display: flex
    justify-content: space-between
    align-items: center
    width: 500px
    height: 250px
    padding: 0 50px
    border-top: 8px solid $main-blue
    border-radius: 4px
    box-sizing: border-box
    background-color: #FFF

  &__icon
    height: 100px
    width: 60px

  &__info
    max-width: 300px
    font-family: "Montserrat"
    font-size: 20px
    color: $faded-red


