@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.dataCell {
  min-width: 220px;
  text-align: start;

  &_highlighted {
    font-size: 14px;
    font-weight: 500;
    color: $boulder-text;

    @include maxW_425 {
      font-size: 12px;
    }
  }

  &_bolded {
    font-size: 18px;
    font-weight: 600;

    @include maxW_425 {
      font-size: 16px;
    }
  }
}
