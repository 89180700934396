@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.third {
  &__header {
    width: 100%;
    text-align: center;
  }

  &__cards {
    @include displayFlex(center);
    margin: 70px 0 30px;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding-top: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }
}
