@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.table {
  margin-bottom: 20px;

  &__mainInfo {
    margin-right: 100px;
  }
}

.disclaimer {
  &__title {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    color: $boulder-text;
  }

  &__list {
    display: grid;
    row-gap: 10px;
  }

  &__item {
    display: grid;
    grid-template-columns: 25px 1fr;
    font-weight: 600;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
