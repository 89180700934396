@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.sixth {
  &__header {
    width: 100%;
    text-align: center;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 650px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 30px 0;
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

   &__label {
    display: block;
    color: $main-white;
    font: normal 500 14px/18px $main-font;
    padding-bottom: 9px;
  }

  &__totalWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }

  &__total {
    display: block;
    color: $main-white;
    font:  normal bold 14px/18px $main-font;
  }

  &__add {
    display: flex;
  }

  &__addIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $main-white;
    color: $main-blue;
    margin-right: 4px;
    margin-top: 2px;
  }

  &__addItem {
    color: $main-white;
    font: normal normal 14px/18px 'Source Sans Pro';
  }
}
