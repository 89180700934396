@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.radioInput {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
  height: 40px;
  opacity: 1;

  &_disabled {
    pointer-events: none;
    opacity: 0.75;
    transition: opacity 1s ease-in;
  }

  &__cell {
    box-sizing: border-box;
    @include displayFlex(center, center);
    flex: 1;
    min-width: 30px;
    padding: 0 5px;
    border: 1px solid $radio-input-border;
    color: $radio-input-color;
    font-size: 18px;
    transition: background-color 0.5s;
    cursor: pointer;

    &_left {
      border-width: 1px 0 1px 1px;
      border-radius: 5px 0 0 5px;
    }

    &_right {
      border-width: 1px 1px 1px 0;
      border-radius: 0 5px 5px 0;
    }

    &_selected {
      border-color: $radio-input-selected-background;
      color: $main-black;
      background-color: $radio-input-selected-background;
    }
  }
}
