@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.FormSlide {
  @include displayFlex(null, center, column);
  flex: 1;

  &__heading {
    @include displayFlex(null, center, column);
    gap: 10px;

    @include maxW_375 {
      @include displayFlex(null, flex-start, column);
      width: 100%;
    }
  }

  &__title {
    font: 24px $quote-terms-title-font;

    @include maxW_375 {
      font-size: 20px;
      align-self: start;
    }

    &_highlighted {
      color: $quote-terms-highlighted-text;
    }
  }

  &__subTitle {
    font: 18px $quote-terms-secondary-font;

    @include maxW_375 {
      font-size: 13px;
      align-self: start;
    }

    &_grey {
      color: $text-grey;
    }
  }

  &__content {
    @include displayFlex(null, center, column);
    gap: 20px;
    width: 100%;
  }

  &__form {
    @include displayFlex(null, center, column);
    width: 100%;

    @include maxW_375 {
      align-items: flex-start;
    }
  }

  &__section {
    @include displayFlex(null, flex-end);
    gap: 20px;

    &_column {
      @include maxW_580 {
        @include displayFlex(null, center, column);
        width: 100%;
      }

      @include maxW_375 {
        @include displayFlex(null, flex-start, column);
      }
    }

    &_divided {
      @include maxW_375 {
        padding-bottom: 20px;
        border-bottom: 1px solid $quote-terms-section-border;
      }
    }
  }

  &__sign {
    color: $custom-input-sign;
    font-size: 18px;
  }

  &__redirect {
    color: $quote-terms-text;
    font-family: $quote-terms-secondary-font;

    @include maxW_375 {
      font-size: 13px;
      align-self: start;
    }

    &_mt25 {
      margin-top: 25px;
    }
  }

  &__file {
    @include displayFlex(null, center);
    margin-top: 15px;
  }

  &__fileName {
    font: 14px $quote-terms-secondary-font;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 120px;
    display: inline-block;
    margin: 0 10px;
  }

  &__navigation {
    position: absolute;
    bottom: 50px;
    display: flex;
    gap: 20px;

    @include maxW_375 {
      bottom: 35px;
      width: 100%;
    }
  }

  &__inputLabel {
    font-size: 14px;
    grid-area: label;
    margin-bottom: 5px;
    color: $custom-input-label;
  
    @include maxW_375 {
      font-size: 13px;
    }
  }
}

