@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.stepSlider {
  display: flex;
  max-width: 460px;
  width: 100%;
  height: 50px;

  @include maxW_675 {
    height: 42px;
    max-width: 400px;
  }

  @include maxW_580 {
    height: 38px;
    max-width: 320px;
  }

  @include maxW_480 {
    height: 36px;
    max-width: 290px;
  }

  @include maxW_375 {
    width: 265px;
    height: 27px;
  }
}

.cell {
  position: relative;
  width: 100%;

  &_withoutLine {
    width: max-content;
  }

  &__container {
    @include displayFlex(null, center);
    width: 100%;
  }

  &__thumb {
    @include displayFlex(center, center);
    box-sizing: border-box;
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: $quote-terms-stepper-color;
    transition: background-color 1s;

    &.white {
      background: rgba(255, 255, 255, 0.5);
    }

    @include maxW_580 {
      width: 18px;
      height: 18px;
    }

    @include maxW_425 {
      width: 15px;
      height: 15px;
    }

    @include maxW_375 {
      width: 9px;
      height: 9px;
    }

    &_current {
      background-color: $quote-terms-stepper-thumb-active;

      &.white {
        background-color: $main-white;
      }
    }
  }

  &__line {
    height: 2px;
    background: $quote-terms-stepper-color;
    width: 100%;
    transition: background-color 0.5s;

    &.white {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    color: $quote-terms-text;
    font-family: $quote-terms-secondary-font;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    transform: translate(-40%);

    &.white {
      color: $main-white;
    }

    @include maxW_580 {
      font-size: 16px;
    }

    @include maxW_425 {
      font-size: 14px;
    }

    @include maxW_375 {
      font-size: 10px;
    }
  }

  &:last-child .cell__title {
    white-space: unset;
    width: 150px;
    bottom: -20px;

    @include maxW_675 {
      bottom: -25px;
    }

    @include maxW_580 {
      width: 120px;
      bottom: -20px;
    }

    @include maxW_375 {
      width: 65px;
      bottom: -10px;
      transform: translate(-45%);
    }
  }

  &_passed {
    .cell {
      &__thumb {
        background-color: $quote-terms-stepper-thumb-active;

        &.white {
          background-color: $main-white;
        }

        @include maxW_375 {
          border: 1px solid $quote-terms-stepper-thumb-active;
          background-color: $main-white;
        }
      }

      &__line {
        background-color: $quote-terms-stepper-thumb-active;

        &.white {
          background-color: $main-white;
        }

        @include maxW_375 {
          background-color: $quote-terms-stepper-color;
        }
      }
    }
  }
}
