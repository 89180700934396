@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.eighth {
  &__header {
    text-align: center;
    width: 100%;
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__range {
    @include displayFlex(null, center);
    gap: 15px;
    padding: 0 10px;
  }

  &__years {
    color: $main-white;
    font-weight: 300;
    min-width: 100px;
  }

  &__selects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px 10px;
  }

  &__tooltipBig {
    color: $main-black;
    font-size: 14px;
    padding-bottom: 2px;
  }

  &__tooltipSmall {
    font-size: 12px;
    padding-bottom: 10px;;
  }

  &__tooltipLink {
    color: $main-black;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }

  &__textarea {
    padding: 0 10px 50px;
  }
}
