@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.projectNameHeader {
  display: flex;
  flex-direction: column;
  padding: 12px 25px;
  background-color: $main-white;
  color: $cornflower-blue;

  @include maxW_425 {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  &__name {
    color: $cornflower-blue;
    font: 500 42px $secondary-font;

    @include maxW_768 {
      font-size: 32px;
    }

    @include maxW_425 {
      font-size: 24px;
    }
  }

  &__address {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 20px;
    font-size: 36px;

    @include maxW_768 {
      font-size: 26px;
    }

    @include maxW_425 {
      flex-direction: column;
      gap: 0;
      font-size: 16px;
    }
  }

  &__link {
    text-decoration: underline;
    white-space: nowrap;
    font: 600 18px $secondary-font;
    color: $blue-text;
    cursor: pointer;

    @include maxW_425 {
      font: 500 14px $secondary-font;
    }
  }
}
