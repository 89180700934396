@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.inputs {
  @include displayFlex(null, null, column);
  gap: 25px;
  margin-top: 35px;

  @include maxW_768 {
    @include displayFlex();
    flex-wrap: wrap;
  }

  &__section {
    @include displayFlex(flex-end, flex-end);
    gap: 20px;

    @include maxW_768 {
      @include displayFlex(flex-start, flex-end);
      flex-wrap: wrap;
    }

    @include maxW_425 {
      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid $quote-terms-section-border;
      }
    }
  }
}

.inputSubtitle {
  font: 18px $quote-terms-secondary-font;
  color: $text-grey;
  
  @include maxW_768 {
    display: none;
  }
}
