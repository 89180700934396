@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.userCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 35px;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__role {
    font-weight: 400;
    color: $boulder-text;
  }

  &__initials {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  margin-top: 15px;
  color: $boulder-text;

  &__item {
    display: flex;
    align-items: center;
  }

  &__email {
    text-decoration: underline;
    color: $blue-text;
  }
}
