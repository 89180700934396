@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.second {
  &__header {
    width: 100%;
    text-align: center;
  }

  &__cards {
    @include displayFlex(center);
    flex-wrap: wrap;
    padding: 0 100px;
    margin-top: 60px;
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }
}
