@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.keyStats {
  &__sections {
    @include displayFlex(null, null, column);
    gap: 40px;
  }
}

.StatsBlock {
  @include displayFlex(null, null, column);
  gap: 20px;

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: $regent-gray;
  }

  &__section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;

    @include maxW_768 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
