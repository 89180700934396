@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.numericInput {
  position: relative;
  display: grid;
  grid-template-rows: auto 42px;
  grid-template-areas:
    'label label'
    'input sign';

  &_reversed {
    grid-template-areas:
      '. label'
      'sign input';
  }

  &__item {
    box-sizing: border-box;
    grid-area: input;
    padding: 0 10px;
    border: 1px solid $custom-input-border;
    border-radius: 5px;
    font-size: 18px;
    outline: none;

    &:focus {
      @include inputFocused;
    }
  }

  &__sign {
    align-self: center;
    margin: 0 0 0 5px;
    grid-area: sign;
    color: $custom-input-sign;
    font-size: 18px;

    @include maxW_375 {
      font-size: 15px;
    }

    &_reversed {
      margin: 0 5px 0 0;
    }
  }

  &_white {
    input {
      background: none;
      border: 1px solid $main-white;
      color: $main-white;
      font: normal 400 14px/18px $main-font;

      &::placeholder {
       color: $main-white;
     }

     &:focus {
       color: $main-white;
     }
    }
  }
}
