@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.graph {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 18px;
    color: #787878;
    font-weight: 500;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    margin-top: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-top: 5px;
    font-size: 13px;
    font-weight: 300;
    color: $main-white;
    background-color: #0a6f66;
  }

  &__budget {
    font-size: 16px;
    font-weight: 500;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5px;
    box-sizing: border-box;
    font-size: 14px;
    background-color: #4db79e;
  }

  &__percent {
    font-size: 16px;
    font-weight: 600;
  }
}
