@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.createNewOfferWrapper {
  box-sizing: border-box;
  position: relative;
  @include displayFlex(null, null, column);
  min-height: 560px;
  width: 100%;
  max-width: 980px;
  padding: 80px 20px 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-image: url('/assets/images/create-project-bg.jpg');
  background-size: cover;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));

  @include maxW_425 {
    margin-top: 0;
    padding: 20px 10px;
    border-width: 1px 0;
    border-radius: 0;
  }

  &__sheetType {
    color: $quote-terms-text;

    @include maxW_375 {
      font-size: 13px;
    }
  }

  &__content {
    @include displayFlex(center);
    flex: 1;

    @include maxW_375 {
      padding: 30px 0 110px;
    }
  }

  &__footer {
    @include displayFlex(space-between, center, column);
    max-height: 140px;
  }

  &__sliderWrapper {
    @include displayFlex(null, center, column);
    width: 100%;
    align-self: flex-end;
  }
}
