@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.keyStatsCell {
    display: grid;
  
    &_secondRow {
      grid-row: 2;
  
      @include maxW_768 {
        grid-row: auto;
      }
    }
  
    &__label {
      color: $regent-gray;
      font-size: 14px;
      min-height: 18px;
    }
  
    &__number {
      font-size: 18px;
      font-weight: 600;
  
      @include maxW_425 {
        font-size: 16px;
      }
    }
  
    &__mainNumber {
      grid-row: 1;
      color: $green-text;
      font-size: 32px;
  
      @include maxW_425 {
        font-size: 24px;
      }
    }
  }